import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  airportDataRedemption: null,
  fieldData: null,
  isLoading: false,
  error: false,
  multiCityFieldArr: [],
  removeSelectedMobileFlight: false,
  multiCityBookingDates: [],
  searchClicked: false
};
const getDateFormat = (date, type) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  if (type) {
    return "".concat(year).concat(month).concat(day).concat(hours).concat(minutes).concat(seconds);
  } else {
    return "".concat(year, "-").concat(month, "-").concat(day);
  }
};
const redemptionSlice = createSlice({
  name: "Redemption",
  initialState,
  reducers: {
    airportRedemptionDataRequest: state => {
      state.isLoading = true;
      state.error = null;
    },
    getAirportRedemptionDataSuccess: (state, action) => {
      state.airportDataRedemption = action.payload;
      state.isLoading = false;
    },
    getAirportRedemptionDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.airportDataRedemption = null;
    },
    updateRedemptionFieldData: (state, action) => {
      const {
        fieldLabel,
        newValue
      } = action.payload;
      if (state.fieldData === null) {
        state.fieldData = {
          from: "",
          to: "",
          departReturn: "",
          depart: "",
          passengers: {}
        };
      }
      if (fieldLabel === "from" || fieldLabel === "to") {
        state.fieldData[fieldLabel] = newValue[0];
      } else if (fieldLabel === "departReturn") {
        const formattedDates = newValue.map(date => {
          if (date === null) return null;
          return getDateFormat(date);
        });
        state.fieldData[fieldLabel] = formattedDates;
      } else if (fieldLabel === "depart") {
        const formattedDate = getDateFormat(newValue[0]);
        state.fieldData[fieldLabel] = formattedDate;
      } else if (fieldLabel === "passengers") {
        state.fieldData[fieldLabel] = newValue.target.value;
      } else {
        state.fieldData[fieldLabel] = newValue.target.value;
      }
    },
    saveRedemptionMultiCityFieldData: (state, action) => {
      state.multiCityFieldArr = action.payload;
    },
    saveRedemptionMultiCityBookingDates: (state, action) => {
      state.multiCityBookingDates = action.payload;
    },
    resetRedeemFieldData: state => {
      state.fieldData = {
        from: "",
        to: "",
        departReturn: "",
        depart: "",
        passengers: ""
      };
    },
    resetRedeemBookFlightLabel: (state, action) => {
      const {
        fieldLabel
      } = action.payload;
      state.fieldData[fieldLabel] = "";
    },
    removeSelectedMobile: (state, action) => {
      state.removeSelectedMobileFlight = action.payload;
    },
    updateSearchButtonSelection: (state, action) => {
      state.searchClicked = action.payload;
    }
  }
});
export const {
  airportRedemptionDataRequest,
  getAirportRedemptionDataFailure,
  getAirportRedemptionDataSuccess,
  updateRedemptionFieldData,
  resetRedeemFieldData,
  resetRedeemBookFlightLabel,
  saveRedemptionMultiCityFieldData,
  saveRedemptionMultiCityBookingDates,
  removeSelectedMobile,
  updateSearchButtonSelection
} = redemptionSlice.actions;
export default redemptionSlice.reducer;